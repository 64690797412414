import React from "react"
import Layout from "../../components/layout"
import MainPoint from "../../components/MainPoint"
import Section from "../../components/Section"
import SectionHeader from "../../components/SectionHeader"
import { Body, Bold, Header2, Header5 } from "../../components/Typography"
import { graphql, Link } from "gatsby"

function AboutSubpage({ data }) {
  return (
    <Layout pageTitle="Our Background">
      <Section>
        <MainPoint
          text={[
            <Header2>Insights on Arnie, our CEO </Header2>,
            <Body>
              As a strong believer in the importance of the work that major gift
              officers do, I felt I could use my unique background to help them
              be more successful. Here’s a little more on me:
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          style={{ alignItems: "start" }}
          text={[
            <div style={{ display: "grid", gridAutoFlow: "row", gap: 8 }}>
              <Header5>A major gift donor</Header5>
              <Body>
                I’ve been on the end of the major gift fundraising process – I
                know what it took to change what would have been a $5,000
                donation into a six figure check. I know what went through my
                head. Seeing it from the donor’s side has given me insight into
                what’s really important when cultivating major donors.
              </Body>
            </div>,
            <div style={{ display: "grid", gridAutoFlow: "row", gap: 8 }}>
              <Header5>Equivalent to a gift officer</Header5>
              <Body>
                I have decades of relationship-selling experience over long
                sales cycles, primarily multi-million dollar software to Wall
                Street financial institutions, global banks, and governments.
              </Body>
            </div>,
            <div style={{ display: "grid", gridAutoFlow: "row", gap: 8 }}>
              <Header5>Equivalent to a VP of Advancement</Header5>
              <Body>
                I have experience managing teams of for-profit gift officer
                equivalents (relationship salespeople who work with prospects
                over many years in order to meet a quota).
              </Body>
            </div>,
            <div style={{ display: "grid", gridAutoFlow: "row", gap: 8 }}>
              <Header5>Your target demographic</Header5>
              <Body>
                I’m also the target demographic for major, principal, and
                planned giving - a Baby Boomer. I don’t have to guess how that
                demographic likes to engage; I live it.
              </Body>
            </div>,
          ]}
          image={data.image}
        />
      </Section>
      <Section style={{ display: "grid", gridAutoFlow: "row", gap: 32 }}>
        <SectionHeader>Adapting to Now</SectionHeader>
        <Header2>Changes in the fundraising industry</Header2>
        <Body>
          I believe that the fundraising industry is at an inflection point
          which is being accelerated by COVID-19. Fortunately, today’s
          technology can help in ways that weren’t possible even just a few
          years ago. There’s a reason though why I chose our slogan,{" "}
          <Bold style={{ color: "var(--primary100" }}>
            <Link to="/artificial-intelligence">
              AI with a Human Touch&trade;
            </Link>
          </Bold>
          . After almost three decades in Silicon Valley, I understand the
          tremendous things that technology can do – but I also know what it
          can't do. At the end of the day, only a human can develop deep
          relationships with a prospect and convert them into a realized donor.
        </Body>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    image: allFile(filter: { relativePath: { eq: "rename/IMG_1700.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default AboutSubpage
